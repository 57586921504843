import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { BfcAuthenticationService } from "@bfl/components/authentication";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private bfcAuthenticationService: BfcAuthenticationService,
    private router: Router) {
  }

  canActivate(): boolean {
    const allowed = this.bfcAuthenticationService.hasRealmRole("ROLE_DAVE_DV");

    if (!allowed) {
      this.router.navigate(["/unauthorized"], { skipLocationChange: true });
    }

    return allowed;
  }
}
