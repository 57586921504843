export const DE_TRANSLATIONS = {
  "SERVICE_NAME": "Nichtverfügbarkeit",
  "ACTIONS": {
    "DELETE": "Löschen",
  },
  "OUTAGE": {
    "TITLE": "Nichtverfügbarkeit",
    "FIELD": {
      "ID": "Anlage",
      "NAME": "Name",
      "START": "Start",
      "END": "Ende",
      "REDUCTION": "Reduzierte Leistung",
      "MAX": "Max. Kapazität (kW)",
      "UNIT": "Einheit",
      "REASON": "Grund",
      "REASON_TEXT": "Anderer Grund",
      "SEGMENT": "Segment",
      "MANUFACTURER": "Hersteller",
      "CATEGORY": "Anlagetyp",
      "ACTION": "Aktion",
    },
    "OVERVIEW": {
      "TITLE": "Übersicht Nichtverfügbarkeiten ",
      "CHOOSE_PARK": "Wählen Sie bitte einen Park aus:",
      "START_DATE": "Von:",
      "END_DATE": "Bis:",
      "BUTTONS": {
        "LINK_ADD": "Neue Nichtverfügbarkeit erfassen",
        "DELETE": "Löschen",
      },
      "CONFIRM_DELETE": {
        "TEXT": "Möchten Sie die Nichtverfügbarkeit wirklich löschen?",
        "TEXT_MORE_THAN_ONE": "Möchten Sie die ausgewählten Nichtverfügbarkeiten wirklich löschen?",
        "CHOSEN_NUMBER": "Ausgewählt: {{numberOfOutages}}",
        "CANCEL": "Abbrechen",
        "SUCCESS": "Erfolgreich gelöscht",
        "ERROR": "Nichtverfügbarkeit konnte nicht gelöscht werden",
        "ERROR_MORE_THAN_ONE": "Nichtverfügbarkeiten konnten nicht gelöscht werden",
      },
      "VIDEO": {
        "BANNER_TEXT": "Benötigen Sie eine Einführung? Schauen Sie sich unser Schulungsvideo an!",
        "LINK": "https://www.bkw.ch/dave_tutorial_1",
        "BTN_CLOSE": "Schliessen",
        "BTN_WATCH": "Anschauen",
      },
    },
    "MANAGE": {
      "TITLE": "Nichtverfügbarkeit erfassen ",
      "STEP1": {
        "TITLE": "Anlage",
        "TITLE_MORE_THAN_ONE": "Anlagen",
        "INTRO_TITLE": "Hier können Sie für einzelne Anlagen Nichtverfügbarkeiten erstellen oder bearbeiten. Wählen Sie bitte eine oder mehrere Anlagen aus:",
      },
      "STEP2": {
        "TITLE": "Leistungsreduktion",
        "INTRO_TITLE": "Erfassen Sie die reduzierte Leistung für die ausgewählte Anlage",
        "INTRO_TITLE_MORE_THAN_ONE": "Erfassen Sie die reduzierte Leistung für die ausgewählte Anlagen",
        "MAX_CAPACITY_ERROR": "Maximale Leistung der Anlage wurde überschritten.",
      },
      "STEP3": {
        "TITLE": "Termin",
        "INTRO": "Hinterlegen Sie bitte den Zeitraum für die Nichtverfügbarkeit",
        "START": "Beginn der Nichtverfübarkeit",
        "END": "Ende der Nichtverfübarkeit",
        "TIME_ERROR": "Endzeit muss nach Startzeit sein",
        "OVERLAPPING_ERROR": "Zu diesem Zeitpunkt besteht bereits mindestends eine Nichtverfügbarkeit.",
      },
      "STEP4": {
        "TITLE": "Grund",
        "INTRO_NAME": "Erstellen Sie bitte einen Namen für die Nichtverfügbarkeit:",
        "INTRO_REASON": "Teilen Sie uns bitte einen Grund für die Nichtverfübarkeit mit",
        "CUSTOM_REASON": "Beschreibung",
      },
      "STEP5": {
        "TITLE": "Aktivierung",
        "INTRO": "Zusammenfassung Nichtverfügbarkeit",
        "DESCRIPTION": "Bitte überprüfen Sie die Zusammenfassung der Nichtverfügbarkeit, bevor Sie diese aktivieren!",
      },
      "BACK": "Zurück",
      "NEXT": "Weiter",
      "SUBMIT": "Jetzt aktivieren",
      "CLOSE": "Schliessen",
      "SUCCESS": "Nichtverfügbarkeit erfolgreich aktiviert",
      "SUCCESS_MORE_THAN_ONE": "Nichtverfügbarkeiten erfolgreich aktiviert",
      "ERROR": "Fehler beim aktivieren der Nichtverfügbarkeit. Prüfen Sie Ihre Eingaben und versuchen Sie es nochmal.",
      "ERROR_MORE_THAN_ONE": "Fehler beim aktivieren der Nichtverfügbarkeiten. Prüfen Sie Ihre Eingaben und versuchen Sie es nochmal.",
      "LINK_BACK": "zurück zur Übersicht",
    },
  },
  "UNIT": {
    "KW": "kW",
    "PERCENTAGE": "%",
  },
  "ERRORS": {
    "SERVICE_UNAVAILABLE": "Der Service ist zur Zeit nicht verfügbar.",
  },
  "ACCESSDENIED": {
    "TITLE": "Kein Zugriff",
    "REASON": "Für diesen Service haben Sie keinen Zugriff.",
  },
  "BACK_BUTTON": "zurück zum Kundencenter",
};
