import { Component, OnInit } from "@angular/core";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";

@Component({
  selector: "app-component",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  constructor(
    private bfcNotificationService: BfcNotificationService,
    private translationService: BfcTranslationService,
  ) {}

  ngOnInit(): void {
    this.bfcNotificationService.showNotification({
      type: BfcNotificationType.INFO,
      message: this.translationService.translate("OUTAGE.OVERVIEW.VIDEO.BANNER_TEXT"),
      options: {
        actions: {
          primary: {
            name: this.translationService.translate("OUTAGE.OVERVIEW.VIDEO.BTN_WATCH"),
            callback: () => this.openVideoLink(),
          },
          secondary: {
            name: this.translationService.translate("OUTAGE.OVERVIEW.VIDEO.BTN_CLOSE"),
            callback: () => this.bfcNotificationService.clearNotifications(),
          },
        },
      },
    });
  }

  openVideoLink(): void {
    window.open(this.translationService.translate("OUTAGE.OVERVIEW.VIDEO.LINK"), "_blank");
  }
}
