import { NgModule } from "@angular/core";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { BfcTranslationModule } from "@bfl/components/translation";
import { BfcDialogModule } from "@bfl/components/dialog";
import { BfcButtonModule } from "@bfl/components/button";
import { CommonModule } from "@angular/common";

@NgModule({
  imports: [
    BfcTranslationModule,
    BfcDialogModule,
    BfcButtonModule,
    CommonModule,
  ],
  exports: [ConfirmDialogComponent],
  declarations: [ConfirmDialogComponent],
  entryComponents: [ConfirmDialogComponent],
  providers: [],
})
export class SharedModule {
}
