import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./core/services/auth-guard.service";
import { translations } from "./config/translations/translations";

const routes: Routes = [
  {
    path: "",
    redirectTo: "outage",
    pathMatch: "full",
  },
  {
    path: "outage",
    loadChildren: () => import("./outage/outage.module").then((m) => m.OutageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "unauthorized",
    loadChildren: () => import("@bfl/components/unauthorized").then(m => m.BfcUnauthorizedModule.asChild(translations)),
  },
  {
    path: "**",
    redirectTo: "error",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
