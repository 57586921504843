import { Component, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmDialogTranslateKeys } from "./model/confirm-dialog-translate-keys.interface";

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
})
export class ConfirmDialogComponent {
  numberOfOutages: number;

  constructor(
    private matDialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ConfirmDialogTranslateKeys,
  ) {
    this.numberOfOutages = data.confirmDialogNumberOfOutages;
  }

  public confirmAction(): void {
    this.matDialogRef.close(true);
  }

  public closeDialog(): void {
    this.matDialogRef.close(false);
  }

  getTitleKey(): string {
    return this.data.confirmDialogTitleKey;
  }

  getTextKey(): string {
    return this.data.confirmDialogTextKey;
  }

  getConfirmKey(): string {
    return this.data.confirmDialogConfirmKey;
  }

  getCancelKey(): string {
    return this.data.confirmDialogCancelKey;
  }
}
