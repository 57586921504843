<div>
    <div mat-dialog-title>
        <h1 class="padding-top-bottom">{{ getTitleKey() | bfcTranslate }}</h1>
    </div>
    <div mat-dialog-content>
        <div>{{ getTextKey() | bfcTranslate }}</div>
        <div *ngIf="numberOfOutages && numberOfOutages > 1" class="bfc-padding-top">{{ "OUTAGE.OVERVIEW.CONFIRM_DELETE.CHOSEN_NUMBER"  | bfcTranslate: {"numberOfOutages": numberOfOutages} }}</div>
    </div>
    <div mat-dialog-actions fxLayoutAlign="space-between center">
        <button mat-button color="primary" class="bfc-button-hero" (click)="closeDialog()">
            {{ getCancelKey() | bfcTranslate }}
        </button>
        <button mat-button color="warn" class="bfc-button-hero" (click)="confirmAction()">
            {{ getConfirmKey() | bfcTranslate }}
        </button>
    </div>
</div>